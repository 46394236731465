<template>
  <div class="custom-absolute-loading flex w-full bg-img" v-if="error">
    <div
      class="
        vx-col
        flex
        items-center
        justify-center
        flex-col
        sm:w-1/2
        md:w-3/5
        lg:w-3/4
        xl:w-1/2
        mx-auto
        text-center
      "
    >
      <img
        src="@/assets/images/pages/404.png"
        alt="graphic-404"
        class="mx-auto mb-4 max-w-full"
      />
      <h1 class="sm:mx-0 mx-4 mb-12 text-5xl d-theme-heading-color">
        404 - Caso no encontrado!
      </h1>
      <p class="sm:mx-0 mx-4 mb-4 sm:mb-16">
        El caso que estas buscando (CID {{ $route.params.cid }}) no fue
        encontrado, por favor verifica que has introducido el CID correctamente.
      </p>
      <vs-button size="large" to="/">Volver</vs-button>
    </div>
  </div>

  <div class="custom-absolute-loading" v-else>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>
<script>
import { db } from "@/firebase/firebaseConfig";

export default {
  data() {
    return {
      error: false,
    };
  },
  async created() {
    const cid = parseInt(this.$route.params.cid);
    const [caseElement] = (
      await db.collection("cases").where("cid", "==", cid).get()
    ).docs;
    if (caseElement && caseElement.exists) {
      this.$router.push({
        name: "main-cases-edit",
        params: { id: caseElement.id },
      });
    } else {
      this.error = true;
    }
  },
};
</script>
